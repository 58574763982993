@use '../../common/colors';

.answer-container {
  flex: 1;
  background-color: colors.$abb-gray-6;
  border: 1px solid colors.$abb-gray-5;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out, color 0.2s ease-in-out;
  padding: 10px 20px;
  min-width: 250px;
  box-shadow: 0 0 10px 1px colors.$abb-gray-5;
  font-size: 1.15em;
}

.answer-container.correct {
  background-color: colors.$abb-green;
  color: colors.$abb-white;
}


.answer-container.wrong {
  background-color: colors.$abb-red;
}

.answer-container:hover:not(.answer-container.wrong, .answer-container.correct, .answer-container.selected, .answer-container.disabled) {
  cursor: pointer;
  background-color: colors.$abb-gray-5;
}

.answer-container:active:not(.answer-container.wrong, .answer-container.correct, .answer-container.selected, .answer-container.disabled) {
  background-color: colors.$abb-gray-4;
  border: 1px solid transparent;
  color: colors.$abb-white;
}

.answer-container.selected {
  background-color: colors.$abb-gray-2;
  border: 1px solid transparent;
  color: colors.$abb-white;
}