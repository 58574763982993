.test-results {
  display: flex;
  flex-direction: column;
  align-items: center;

  .score-chart-container {
    min-width: 250px;
    min-height: 250px;
  }

  .score-text {
    font-size: 1.4em;
  }

  .final-text {
    font-size: 1.2em;
    font-weight: bold;
  }

  .calltoaction-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}