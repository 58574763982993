@use 'topbar';
@use '../common/colors';

.user-profile-container {
  padding-right: 1vw;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 2;
}

.user-profile-container:hover {
  cursor: pointer;
}

.user-completename {
  margin-right: 1vw;
  display: inline-block;
}

.user-dropdown {
  border-right: 1px solid colors.$abb-gray-5;
  border-left: 1px solid colors.$abb-gray-5;
  border-bottom: 1px solid colors.$abb-gray-5;
  box-shadow: -1px 1px 3px colors.$abb-gray-4;
  width: 250px;
  height: 400px;
  position: absolute;
  top: topbar.$top-bar-height;
  right: 0;
  transition: opacity 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 15;
  background-color: colors.$abb-white;
  visibility: hidden;
  font-size: 1.2em;
}

.user-dropdown.visible {
  visibility: visible;
}

.user-dropdown-button {
  color: colors.$abb-black;
  text-align: left;
  text-decoration: none;
  padding: 25px 20px;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease-in-out;
  flex-direction: row;
}

.user-dropdown-button:hover {
  background-color: colors.$abb-gray-6;
  transition: background-color 0.15s ease-in-out;
}

.user-dropdown-button:active {
  cursor: pointer;
  background-color: colors.$abb-gray-5;
}


.user-dropdown-button-icon {
  margin-left: auto;
}