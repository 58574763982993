#explore-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;

  .explore-title {
    margin-block-end: 0;
    margin-bottom: 50px;
  }
}