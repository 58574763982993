@use '../common/colors';

.text-with-tooltip {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: visible;

  p {
  }

  svg {
    margin-left: 6px;
  }

  svg:hover {
    cursor: pointer;
  }

  .tooltip {
    position: absolute;
    transition: opacity 0.3s ease-in-out;
    background-color: colors.$abb-gray-5;
    padding: 2px 5px;
    border: 1px solid colors.$abb-gray-4;
    box-shadow: 0 0 3px 1px colors.$abb-gray-5;
    display: inline-flex;

    p {
      width: 100%;
    }

    svg {
      font-size: 1.4em;
    }
  }

  .tooltip.hidden {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
}