@use '../components/topbar';

#home-content {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  justify-content: center;
  padding-top: 10vh;
  gap: 25px;

  #home-search-bar {
    width: 50vw;
    max-width: 1000px;
  }

  .logo {
    width: 250px;
    margin-bottom: max(2.5vh, 50px);
  }

}