@use '../common/colors';

.search-result-item, .video-preview {
  .MuiBadge-badge {
    transform: translate(-10px, -10px);
    font-size: 1.25rem;
    padding: 20px 10px;
  }

}

.video-preview .MuiBadge-badge {
  border-radius: 10px;
  padding: 10px 40px;
}