@use '../common/colors';

.tag-link {
  color: colors.$abb-gray-1;
  transition: text-decoration-color 0.2s ease-in-out;

  .tag-link-end {
    display: inline-block;
    text-decoration: none;
  }
}

.tag-link:hover {
  text-decoration-color: colors.$abb-red;
}