#test-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;

  .loading-correct-answer {
    margin-top: 50px;
  }

  .disclaimer {
  }

  .start-test-button {
    margin-top: 50px;
  }

  .test-progress-bar {
    width: 100%;
  }

  .confirm-answer-button {
    margin-top: 50px;
  }

  .test-unavailable {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}