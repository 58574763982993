.language-switcher {
  display: flex;
  align-items: center;
  margin-left: 1vw;
  flex: 1.5;
  color: black;

  svg {
    height: 15px;
    width: 22.5px;
    display: flex;
  }

  .language-switcher-icon {
    height: 25px;
    color: black;
  }

  .language-switcher-select {
    flex: 1;
    margin-left: 5px;
  }
}