@use '../components/topbar';
@use '../common/colors';

#course-content {
  flex: 1;
  display: flex;
  padding-top: 25px;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: 80%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .lessons {
    width: 100%;
    margin-top: 25px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    justify-items: flex-start;
    align-self: stretch;
  }

  .header {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-self: stretch;

    .header-title {
      margin-block-end: 0.05em;
      font-size: 3em;
    }
  }

  .header-info {
    color: colors.$abb-gray-2;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    justify-content: flex-start;
    flex: 1;

    p {
      text-align: end;
      flex: 1;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .header-info-time {
    display: flex;
    align-items: center;

    p {
      margin-left: 5px;
    }
  }

  .header-tags-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 20px;
    margin-top: 15px;
  }

  .description {
    font-size: 1.2em;
    flex: 1;
  }

  .lessons {
    flex: 5;
  }

  .lessons-separator {
    border-left: 5px solid colors.$abb-gray-3;
    height: 100px;
  }

  .lessons-separator.highlighted {
    border-color: colors.$abb-red;
  }

  .lessons-final-circle {
    width: 45px;
    height: 45px;
    border: 5px solid colors.$abb-gray-3;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: colors.$abb-gray-3;

    svg {
      font-size: 20px;
    }
  }

  .lessons-final-circle.finished {
    border-color: colors.$abb-red;

    svg {
      color: colors.$abb-red;
    }
  }

  .course-favorite-button:hover {
    cursor: pointer;
  }
}