@use '../common/colors';

.explore-box {
  .MuiBadge-badge {
    transform: translate(-10px, -5px);
    border-radius: 1vmin;
    height: 25px;
    font-size: 1.25rem;
    padding: 20px 40px;
  }
}