@use '../common/colors';

.tag-button {
  color: inherit;
  text-decoration: none;

  padding: 10px 30px;
  border: 1px solid colors.$abb-gray-4;
  border-radius: 10px;

  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  position: relative;

  .tag-button-usage {
    position: absolute;
    top: -15px;
    right: 2px;
    background-color: colors.$abb-gray-2;
    color: colors.$abb-white;
    border-radius: 50%;
    padding: 5px 12px;
  }
}

.highlight {
  background-color: colors.$light-yellow;
}

.tag-button:hover {
  background-color: colors.$abb-red;
  color: colors.$abb-white;
}


