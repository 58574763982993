$abb-red: #ff000f;
$abb-black: #000000;
$abb-white: #ffffff;

$abb-gray-1: #262626;
$abb-gray-2: #6e6e6e;
$abb-gray-3: #a9a9a9;
$abb-gray-4: #d2d2d2;
$abb-gray-5: #f0f0f0;
$abb-gray-6: #fafafa;

$abb-blue: #004c97;
$abb-green: #007a33;
$abb-yellow: #ffd100;

$link: #3366ff;
$light-yellow: #fffa00;