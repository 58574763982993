@use '../common/colors';

.lesson-video-player {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  --plyr-color-main: #ff000f;

  .preview {
    box-shadow: 0 0 10px 1px colors.$abb-gray-4;
  }

  .plyr-container {
    box-shadow: 0 0 10px 1px colors.$abb-gray-4;
    width: 90vw;
    height: 50.62vw;

    max-width: 1280px;
    max-height: 720px;

    @media screen and (max-width: 768px) {

    }

    .plyr--video {
      height: 100%;
    }
  }

  .plyr__poster {
    pointer-events: none;
    z-index: 5;
  }

  button.plyr__control {
    z-index: 6;
  }

  .plyr-container.loading {
    width: min(1280px, 50vw);
    height: min(720px, 28.125vw);
    box-shadow: none;
  }

  .plyr-container.loading .plyr {
    visibility: hidden;
  }

  .preview-container {
    position: relative;

    .play-icon {
      position: absolute;
      color: colors.$abb-white;
      top: 50%; /* position the top  edge of the element at the middle of the parent */
      left: 50%; /* position the left edge of the element at the middle of the parent */

      transform: translate(-50%, -50%);
      /* This is a shorthand of
                                              translateX(-50%) and translateY(-50%) */

      font-size: 5em;
      filter: drop-shadow(0px 0px 15px colors.$abb-gray-1);

      transition: filter 0.2s ease-in-out;
    }

    .play-icon:hover {
      cursor: pointer;
      filter: drop-shadow(0px 0px 5px colors.$abb-gray-1);
    }
  }

  .preview:hover {
    cursor: pointer;
  }
}