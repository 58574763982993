#certificates-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .header-title {
    }
  }

  .search-bar-container {
    width: 100%;
  }

  .certificates-search-bar {
    max-width: 1920px;
  }

  .certificates-list {
    margin-top: 50px;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    gap: 50px;
    justify-content: flex-start;
    align-items: center;

    .loading, .error {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }

  .certificates-list::after {
    content: "";
    flex: auto;
  }
}