@use '../common/colors';

#lesson-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;

  .progress-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    width: 250px;

    p {
      color: colors.$abb-gray-2;
    }
  }

  .progress-buttons {
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
  }

  .hidden {
    visibility: hidden;
  }

  .lesson-favorite-button:hover {
    cursor: pointer;
  }

  .final-lesson-call-to-action {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;

    p {
      font-size: 1.2em;
    }

    .final-lesson-button {

    }
  }

  .next-previous-lesson-preview {
    border: 1px solid colors.$abb-gray-3;
    margin-top: auto;
  }

  .requirements-list {
    width: 100%;
  }

  .requirement {

  }

}