@use '../common/colors';

.page-switcher {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;

  span {
    margin-bottom: 10px;
    color: colors.$abb-gray-2;
  }

  .page-switcher-button-row {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  .page-switcher-button {
    margin-left: 10px;
    margin-right: 10px;

    font-size: 1.2em;
  }

  .page-switcher-button.clickable {
    color: colors.$link;
  }

  .page-switcher-button.clickable:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}