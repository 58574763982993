@use '../common/colors';

.explore-row {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  .explore-row-content {
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
    box-sizing: border-box;

    z-index: 10;
    overflow-x: scroll;
    padding: 15px 25px 15px 9vw;
    white-space: nowrap;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .explore-row-content::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .explore-row-content {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .carousel-container {
    width: 100vw;
    // transform: translate(-50%, 0);
    // left: 50%;

    .react-multi-carousel-track {
      padding-left: 1vw;
    }


    .carousel-arrow-container {
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 100;
      height: 350px;
      width: 100px;
      position: absolute;
      top: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .carousel-arrow-right-container {
      right: 0;
    }

    .carousel-arrow-left-container {
      left: 0;
    }

    .carousel-arrow-container:hover {
      cursor: pointer;
    }

    .carousel-arrow {
    }

    .carousel-arrow-icon {
      font-size: 3em;
    }
  }
}