@use '../common/colors';

.preview-box {
  .img-container {
    .MuiBadge-badge {
      transform: translate(-10px, -5px);
      border-radius: 10px;
      height: 25px;
      padding: 15px 35px;
    }
  }
}