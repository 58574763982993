@use '../common/colors';

@media screen {
  #completion-certificate {
    width: 50vw;
    height: 35.35vw;
    border: 1px solid colors.$abb-black;
    box-shadow: 0 0 20px 10px colors.$abb-gray-4;
    margin-top: 5vh;
    margin-bottom: 5vh;

    .flavor {
      font-size: 0.75vw;
    }

    .important {
      font-size: 1.5vw;
    }

    .result {
      font-size: 2vw;
    }
  }
}


#completion-certificate {
  display: flex;

  background-image: url("../../assets/img/certbg.png");
  background-size: cover;

  box-sizing: border-box;

  flex-direction: column;
  align-content: center;
  align-items: center;

  padding: 5% 10%;

  text-align: center;

  .content {
    padding-top: 5%;
    padding-bottom: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logo {
    width: 30%;
  }

  .flavor, .important, .result {
    margin-top: 2.5%;
  }

  .flavor {
    font-weight: lighter;
  }

  .important, .result {
    font-weight: bold;
  }

  .result {
    margin-top: 7.5%;
  }

  overflow: hidden;

}


@media print {
  @page {
    size: A4 landscape;
    margin: 0;
  }

  .completion-certificate-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10000;
    background-color: colors.$abb-white;

    #completion-certificate {
      width: 29.7cm;
      height: 21.0cm;

      -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
      color-adjust: exact !important; /*Firefox*/

      .flavor {
        font-size: 14pt;
      }

      .important {
        font-size: 26pt;
      }

      .result {
        font-size: 30pt;
      }
    }
  }
}