@use '../common/colors';

#profile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 25px;

  .profile-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .profile-title {
    font-size: 3em;
    margin-block-end: 0;
  }

  .profile-settings {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 1280px;
  }

  .section-header {
    width: 100%;
    color: colors.$abb-gray-2;
    padding-bottom: 5px;
    border-bottom: 1px solid colors.$abb-gray-4;
  }

  .profile-workgroups-container, .profile-managed-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 50px;
  }

  .profile-workgroups-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid colors.$abb-gray-5;
    padding-top: 15px;
    padding-bottom: 15px;

    .label-group-name, .label-manager {
      flex: 0.5;
      font-size: 1.5em;
    }

    .label-group-name {
      margin-right: 25px;
    }
  }

  .profile-workgroup {
    display: flex;
    align-items: center;
    border-bottom: 1px solid colors.$abb-gray-5;
    padding-top: 15px;
    padding-bottom: 15px;

    .profile-workgroup-name {
      margin-right: 25px;
      font-weight: bold;
      font-size: 1.2em;
      flex: 0.5;
    }

    .profile-workgroup-manager {
      flex: 0.5;
    }
  }

  .profile-managed-container {
    gap: 25px;

    .new-workgroup-button {
      align-self: flex-end;
    }

    .profile-managed-workgroup {
      background-color: colors.$abb-white;
      box-shadow: 0 0 5px colors.$abb-gray-4;
      padding: 25px 50px;
      transition: background-color 0.3s ease-in-out;

      display: flex;
    }

    .profile-managed-workgroup:hover {
      background-color: colors.$abb-gray-6;
      cursor: pointer;
      transition: background-color 0.15s ease-in-out;
    }

    .profile-managed-workgroup:active {
      background-color: colors.$abb-gray-5;
      cursor: pointer;
    }

    .profile-managed-workgroup-name, .profile-managed-workgroup-members-count {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .profile-managed-workgroup-members-count {
      margin-left: auto;
      margin-right: 25px;
    }
  }
}

.new-workgroup-modal {
}