@use '../common/colors';

.abb-title {
  width: -moz-fit-content;
  width: fit-content;
  line-height: 1;
  margin-top: 2.5vh;
}

.abb-title.primary {
  font-size: 4em;
}

.abb-title.secondary {
  font-size: 3em;
}

.cursor.primary {
  color: colors.$abb-red;
}

.cursor.secondary {
  color: colors.$abb-black;
}