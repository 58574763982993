@use '../common/colors';
@use 'topbar';

.topbar-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
  height: topbar.$top-bar-height;
  text-decoration: none;
  color: colors.$abb-black;
  font-size: 1.3em;
  transition: box-shadow 0.1s ease-in-out, background-color 0.2s ease-in-out;
}

.topbar-button:hover, .topbar-button.highlighted:hover {
  cursor: pointer;
  background-color: colors.$abb-gray-5;
  -webkit-box-shadow: inset 0px -10px 0px -5px colors.$abb-red;
  -moz-box-shadow: inset 0px -10px 0px -5px colors.$abb-red;
  box-shadow: inset 0px -10px 0px -5px colors.$abb-red;
  transition: background-color 0.15s ease-in-out;
}

.topbar-button:active {
  cursor: pointer;
  background-color: colors.$abb-gray-4;
}

.topbar-button.highlighted {
  -webkit-box-shadow: inset 0px -10px 0px -8px colors.$abb-red;
  -moz-box-shadow: inset 0px -10px 0px -8px colors.$abb-red;
  box-shadow: inset 0px -10px 0px -8px colors.$abb-red;
}

.topbar-button-selected {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
  height: topbar.$top-bar-height;
  text-decoration: none;
  color: colors.$abb-black;
  font-size: 1.3em;
  transition: box-shadow 0.1s ease-in-out, background-color 0.2s ease-in-out;
  background-color: colors.$abb-gray-5;
  -webkit-box-shadow: inset 0px -10px 0px -5px colors.$abb-red;
  -moz-box-shadow: inset 0px -10px 0px -5px colors.$abb-red;
  box-shadow: inset 0px -10px 0px -5px colors.$abb-red;
}