@use '../common/colors';

#wrapped-content {

  padding-left: 10vw;
  padding-right: 10vw;
  padding-bottom: 10vh;
  width: 80vw;
  max-width: 1920px;
  overflow-x: visible;

  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  @media screen and (min-width: 769px) and (max-width: 1300px) {
    min-width: 100%;
  }

  @media screen and (max-width: 768px) {
    min-width: 0;
    padding-top: 50px; // little trick to fill the space left free by the sticky topbar
    padding-left: 5vw;
    padding-right: 5vw;
    box-sizing: content-box;
    width: 90vw;
  }
}

footer {
  margin-top: auto;
  margin-bottom: 50px;
}

.dev-disclaimer {
  color: colors.$abb-red;
  font-weight: bold;
  font-size: 1.5em;
  margin-top: 2.5vh;
}