#tags-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 50px;

    .header-title {
    }
  }

  .tags-list {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    justify-content: space-between;
  }

  .tags-list::after {
    content: "";
    flex: auto;
  }

  .search-bar-container {
    width: 100%;
  }
}