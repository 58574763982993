#bookmarks-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 50px;

    .header-title {
    }
  }

  .search-bar-container {
    width: 100%;
  }

  .bookmarks-search-bar {
    max-width: 1920px;
  }

  .bookmarks-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    .bookmark-item {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}