@use '../common/colors';

#search-results-content {
  width: 100%;
  max-width: 1920px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .search-results-title {
    font-size: 2.5em;
  }

  .search-filters-box {
    display: flex;
    margin-top: 20px;
    margin-bottom: 25px;
    flex-wrap: wrap;
  }

  .search-filters-types {
    width: 100%;
    z-index: 100;
  }

  .filters-type-container {
    display: flex;
    align-items: flex-end;
  }

  .filters-type {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 250px;
  }

  .filters-label {
    color: colors.$abb-gray-2;
  }

  .filters-sort-order-container {
    display: flex;
    align-items: flex-end;
    margin-left: auto;
  }

  .search-filters-sort {
    width: 100%;
  }

  .filters-sort {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 250px;
  }

  .results {
    display: flex;
    flex-direction: column;
    align-items: center;

    .no-results {
      font-size: 1.2em;
    }
  }

  .filters-left-box {
    align-items: flex-end;
  }

  .filters-right-box {
    margin-left: auto;
    align-items: center;
  }
}