@use '../common/colors';

.certificate-box {
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  width: 250px;
  height: 250px;
  padding: 10px 20px;

  box-shadow: 0 0 5px colors.$abb-gray-4;

  justify-content: space-between;

  .course-name {
    font-weight: bold;
    font-size: 1.2em;
  }

  .datetime {
  }

  .score {
    font-size: 4em;
    align-self: center;
  }

  background-image: url("../../assets/img/certbg.png");
  background-size: cover;
  background-blend-mode: multiply;

  transition: background-color 0.3s ease-in-out;
}

.certificate-box:hover {
  cursor: pointer;
  background-color: colors.$abb-gray-5;
}

.certificate-box:active {
  cursor: pointer;
  background-color: colors.$abb-gray-4;
}