@use '../common/colors';

$top-bar-height: 80px;
$logo-width: 100px;

#top-bar {
  position: sticky;
  top: 0;
  z-index: 110;
  height: $top-bar-height;
  width: 100%;
  background-color: colors.$abb-gray-6;
  border-bottom: 1px solid colors.$abb-gray-5;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;


  #logo {
    display: flex;
    align-items: center;
    justify-items: center;
    padding-left: 20px;

    img {
      max-width: $logo-width;
      max-height: $top-bar-height * 0.75;
      object-fit: cover;
    }

    flex: .5;
  }

  .topbar-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 4;
    margin-left: auto;
  }

  .topbar-lang-switcher {
    flex: 1.5;

    div {
      max-width: 210px;
    }
  }
}

#drawer {
  .active > .drawer-link {
    border-left: 5px solid colors.$abb-red;
  }
}