@use '../common/colors';

#manage-workgroup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  min-height: 81vh;

  .section-header {
    width: 100%;
    color: colors.$abb-gray-2;
    padding-bottom: 5px;
    border-bottom: 1px solid colors.$abb-gray-4;
  }

  .members-list-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 25px;
    margin-bottom: 100px;

    .members-list-header {
      display: flex;
      justify-content: flex-end;
      gap: 20px;
    }
  }

  .members-list {
    width: 100%;
    list-style-type: none !important;
  }
}